<template>
<scale-box>
	<div class="patentList-box">
       <div class="patentList-but" @click="back">返回</div>
		<div class="patentList-middle">
            <div class="middle-left">
                <div class="left-title">商标</div>
                <div class="left-list">
                    <div class="list-title">
                        <div>排序</div>
                        <div>商标名称</div>
                        <div class="ico-div">
                          <span>申请日</span>
                          <span>
                            <i class="el-icon-caret-top" @click="tradeUp"></i> <i class="el-icon-caret-bottom" @click="tradeDown"></i>
                          </span>
                        </div>
                        <div class="trade-select">
                            <span>状态</span>
                            <el-select v-model="value1" placeholder="请选择" @change="chose1" style="width:100px;margin-left:10px">
                            <el-option v-for="item in optionm2"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.id"></el-option>
                        
                        </el-select>
                        </div>
                        <div>操作</div>
                    </div>
                    <div style="width: 840px;height:650px;overflow:auto;margin-left:25px">
                        <div class="list-detail" v-for="(v,k) in tableData1" :key="k">
                            <div>{{k+1}}</div>
                            <div class="detail-name" :title="v.sbmc">{{v.sbmc}}</div>
                            <div>{{v.appDate}}</div>
                            <div>{{v.tmstatus}}</div>
                            <div @click="watchDeatail(v.regId,v.intCls)" style="cursor: pointer;">查看详情</div>
                        </div>
                    </div>
                    
                </div>
                    <div class="flex align-center" style="background-color:transparent;">
                    <el-pagination :current-page="from1 + 1" style="margin: 20px auto 0;width: 800px;text-align:center;padding-top:10px"
                        :page-sizes="[20, 50, 100, 200]"
                        :page-size="size1"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total1"
                        @size-change="pageSizeChanged1"
                        @current-change="currentChanged1"
                    />
                    </div>
            </div>
            <div class="middle-left mt">
                <div class="right-title">
                    <div>
                        专利
                    </div>
                    <div class="right-search">
                    <input type="text" v-model="keyword"  placeholder="请输入专利名称">
                        <span class="tab-searh-span" @click="searh()">搜索</span>
                    </div>
                </div>
                <div class="left-list">
                    <div class="list-title">
                        <div>排序</div>
                        <div style="width:390px;">专利名称</div>
                        <div class="ico-div">
                            <span>申请日</span>
                            <span>
                                <i class="el-icon-caret-top" @click="patentUp"></i> <i @click="patentDown" class="el-icon-caret-bottom"></i>
                            </span>
                        </div>
                        <div class="trade-select">
                            <span>类型</span>
                            <el-select v-model="value2" placeholder="请选择" @change="chose2" style="width:100px;margin-left:10px;">
                            <el-option v-for="item in optionm"
                                    :key="item.name"
                                    :label="item.name"
                                    :value="item.id"></el-option>
                        
                        </el-select>
                        </div>
                        <!-- <div>操作</div> -->
                    </div>
                    <div style="width: 840px;height:650px;overflow:auto;margin-left:25px">
                        <div class="list-detail" v-for="(v,k) in tableData2" :key="k">
                            <div>{{k+1}}</div>
                            <div @click="topatent(v.zymc)" :title="v.zymc" class="detail-name" style="width:390px;cursor: pointer;">{{v.zymc}}</div>
                            <div>{{v.sqr}}</div>
                            <div>{{v.zllx}}</div>
                            <!-- <div style="cursor: pointer;">查看详情</div> -->
                        </div>
                    </div>
                </div>
                <div class="flex align-center" style="background-color:transparent;">
                    <el-pagination :current-page="from2 + 1" style="margin: 20px auto 0;width: 800px;text-align:center;padding-top:10px"
                        :page-sizes="[20, 50, 100, 200]"
                        :page-size="size2"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total2"
                        @size-change="pageSizeChanged2"
                        @current-change="currentChanged2"
                    />
                    </div>
            </div>
        </div>
        <div style="clear;both"></div>
	</div>
    </scale-box>
</template>
<script>
import scaleBox from "../../components/data/sclae-box";
export default {
  name: '',
  data(){
    return {
      tableData1:[],
      from1:0,
	  total1:0,
	  size1:20,
      tableData2:[],
      from2:0,
	  total2:0,
	  size2:20,
       province:"",
      city:"",
      county:"",
      level:"",
      value1:'',
      value2:'',
      keyword:'',
      optionm:[{
		id:'',
        name:'全部类型'
      },{
		id:"faming_shenqing",
        name:'发明申请'
      },{
		id:'faming_shouquan',
        name:'发明授权'
      },{
		id:"shiyong",
        name:'实用新型'
      },{id:"waiguan",
        name:'外观'
      }],
       optionm2:[{
		id:'',
        name:'全部类型'
      },{
		id:"有效",
        name:'有效'
      },{
		id:'无效',
        name:'无效'
      },{
		id:"其他",
        name:'其他'
      }],
      lx:[],
      pai:'DESC',
      pai2:'DESC',
      word:this.$route.query.company,
      address:this.$route.query.address
    }
  },
 
  components: {
   scaleBox
  },
  watch:{
    
    
  },
  methods: {
	pageSizeChanged1(val){
		this.size1 = val
		this.from1 =0;
	    this.getData1()
	},
     currentChanged1(val){
		this.from1 = val - 1
		this.getData1()
	 },
     pageSizeChanged2(val){
		this.size2 = val
		this.from2 =0;
	    this.getData2()
	},
    topatent(val){
       this.$router.push({
         path:'/searchDetail',
         query:{
            word:val
         }
      })
     },
     currentChanged2(val){
		this.from2 = val - 1
		this.getData2()
	 },
     back(){
        this.$router.push({
			path:"/patentData",
			query:{
				from:0
			}
		})
     },
     searh(){
        this.from2 = 0
		this.getData2()
     },
     watchDeatail(val1,val2){
        this.$router.push({
			path:"/brandDetail",
			query:{
				category:val2,
                regNum:val1,
                companyName:this.word
			}
		})
     },
     chose1(){
        this.from1 =0;
        this.getData1()
     },
      chose2(){
        this.from2 =0;
       this.lx.length = []
       if(this.value2!=""){
        this.lx.push(this.value2)
      }
      this.getData2()
     },
      tradeUp(){
         this.pai2 = "ASC";
      this.from1 = 0;
      this.getData1()
     },
     tradeDown(){
        this.pai2 = "DESC";
      this.from1 = 0;
      this.getData1()
     },
     patentUp(){
         this.pai = "ASC";
      this.from2 = 0;
      this.getData2()
     },
     patentDown(){
        this.pai = "DESC";
      this.from2 = 0;
      this.getData2()
     },
	 getData1(){
		
				this.$http.post(this.GLOBAL.new_url + '/trademark_all/service/data/getTrademarkByApplicant',
					{
                    "applicant": this.word,
                    "address":this.address,
                    "from": this.from1,
                    "size": this.size1,
                    "level": this.level,
                    "province": this.province,
                    "city": this.city,
                    "county":this.county,
                    "sort":"sqr",
                    "orderBy":this.pai2,
                    "tmstatus":this.value1
					}
            ).then((res) => {
                // console.log(res)
				this.tableData1.length = 0
                this.total1 = 0;
				this.tableData1.push(...res.data.data)
                this.tableData1.map((x,y)=>{
                   x.appDate = x.appDate.split("T")[0]
                })
				if (res.data.data.length != 0) this.total1 = res.data.data[0].total		
              } 
			)		
	 },
     getData2(){
		      let name = [];
              name.push(this.word)
				this.$http.post(this.GLOBAL.new_url + '/patent/service/data/patentQuery',
					{
					"year":  '',
					"level": this.level,
                    "province": this.province,
                    "city": this.city,
                    "county":this.county,
                    "from": this.from2,
                    "size":this.size2,
                     "zymc":"",
                    "zlzy":"",
                    "fmr":[],
                    "sqrmc":name,
                    "lx":this.lx,
                    "fenlei":[],
                    "sort":"sqr",
                    "orderBy":this.pai,
                    "sqh":"",
                    "text":this.keyword
					}
            ).then((res) => {
                // console.log(res)
				this.tableData2.length = 0
                this.total2 = 0
				this.tableData2.push(...res.data.data)
                this.tableData2.map((x,y)=>{
                   x.sqr = x.sqr.split("T")[0]
                })
                // console.log(this.tableData2)
				if (res.data.data.length != 0) this.total2 = res.data.data[0].total	
              } 
			)		
	 },
    
  },
   mounted() {
    this.province=localStorage.getItem('province');
      this.city=localStorage.getItem('city');
      this.county=localStorage.getItem('county');
      this.level=localStorage.getItem('level');
      if(this.county =="null"){
         this.county="";
      }
		if(this.level == "null"){
         this.level="";
      }
      this.getData1();
      this.getData2();
    },
     beforeCreate() {
            document.querySelector('body').setAttribute('style', 'background-color:#0e2140;')
        },
}
</script>

<style scoped>
	.patentList-box{
        width: 1920px;
        height: 1080px;
        background-size: cover;
        background: url('../../../static/data.png') 100% no-repeat;
        margin: 0 auto;
        position: relative;
    }
   .patentList-but{
    position: absolute;
    width: 120px;
    height: 40px;
    background: #002C8C;
    box-shadow: inset 0px 5px 15px 1px #1890FF;
    font-size: 20px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
    top:24px;
    right:64px;
   }
   .patentList-middle{
    width: 1825px;
    margin: 125px auto 0;
   }
   .patentList-middle>div{
    float: left;
   }
   .middle-left{
    text-align: center;
    margin-right: 40px;
   }
   .mt{
    margin-right:0 ;
   }
   .left-title{
    height: 35px;
    font-size: 28px;
    font-family: PingFang SC-Bold, PingFang SC;
    margin-bottom: 28px;
    color: #18F8FF;
   }
   .left-list{
    width: 887px;
    height: 740px;
    padding-top: 20px;
    background: url('../../../static/images/patentList.png') 100% no-repeat;
   }
   .list-title{
    height: 64px;
    border-bottom:1px solid #69B1FF;
    margin: 0 auto;
    width: 840px;
   }
   .list-title>div,.list-detail>div{
    float: left;
    width: 195px;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 64px;
    text-align: center;
   }
   .list-title>div:nth-of-type(1),.list-detail>div:nth-of-type(1){
    width: 60px;
    text-align: center;
   }
   .list-detail{
    height: 63.5px;
    border-bottom:1px solid #69B1FF;
    margin: 0 auto;
    width: 840px;
   }
   .detail-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
   }
   .list-detail:hover{
    background: #264C8F;
   }
   :deep(.el-pagination){
	height: 35px;
	border:1px solid #91CAFF;
	background: #1A3876;
	vertical-align: middle;
	color: #fff;
	border-radius:0 0 20px 20px ;
 }
 :deep(.el-pagination .btn-next),:deep(.el-pagination .btn-prev){
	color: #fff!important;
	background: transparent;
 }
 :deep(.el-pager){
	background: #1A3876;
 }
 :deep(.el-pager li){
	background: #1A3876;
 }
 :deep(.el-pagination__total),:deep(.el-pagination__jump){
	color: #fff!important;
 }
 :deep(.el-pager li:not(.disabled).active),:deep(.el-pager li:hover){
        color: #18F8FF !important;
    }
:deep(.el-pager li.btn-quickprev),:deep(.el-pager li.btn-quicknext){
		color: #fff !important;
	}
:deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
	padding-left: 0!important;
	background: transparent!important;
}
:deep(.el-pagination) span{
	font-size: 18px!important;
 }
:deep(.el-pager li){
	font-size: 18px;
 }
  .ico-div{
    position: relative;
   }
  .el-icon-caret-top{
    position: absolute;
    top:15px;
    right: 35px;
    cursor: pointer;
  }
  .el-icon-caret-bottom{
      position: absolute;
   top:28px;
    right: 35px;
    cursor: pointer;
  }
  .trade-select :deep(.el-input__inner){
	border:1px solid #91CAFF!important;
	color: #fff !important;
    height:32px;
    padding-left: 10px!important;
	background: transparent!important;
}
.right-title{
    height: 35px;
    margin-bottom: 28px;
    position: relative;
    margin-bottom: 28px;
}
.right-title>div:nth-of-type(1){
    position: absolute;
    font-size: 28px;
    font-family: PingFang SC-Bold, PingFang SC;
    color: #18F8FF;
    left: 50%;
    transform: translate(-50%,0);
}
.right-title>div:nth-of-type(2){
    position: absolute;
    right: 25px;
}
.right-search>input{
    width: 185px;
    height: 35px;
    padding-left: 15px;
    outline: none;
    background: transparent;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    margin-right: 8px;
    border: 1px solid #BAE0FF;
}
.right-search>span{
    display: inline-block;
    width: 72px;
    vertical-align: middle;
    height: 37px;
    font-size: 20px;
    color: #fff;
    background: #264C8F;
    box-shadow: inset 0px 3px 6px 1px rgba(64,150,255,0.16);
    border-radius: 4px;
    cursor: pointer;
    margin-top: -5px;
    line-height: 37px;
    text-align: center;
}
</style>


